export default [
  {
    id: "media-interaction-01",
    title: "Genesis JK EV FL 국내런칭 프로모션",
    category: "Media Interaction",
    image: "/images/VJ_Title_Image/VJ_title_47.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_47.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_01.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_02.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_03.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_04.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_05.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_06.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_07.png",
      "/images/workImages/47.GenesisGv70/GenesisGv70_08.png",
    ],
    date: "2025-01-17 ~ 26",
    location: "Gyeonggi, Republic of Korea",
    description:
      "2025 일렉트리파이드 GV70 EV 프로모션에 들어가는 디자인 및 프로그램 개발을 진행했습니다.",
    tasks: [
      "페달 연동 프로그램 개발",
      "CCIC 체험 디자인 및 개발",
      "초대장 홈페이지 개발",
      "개발 오퍼레이팅",
    ],
  },

  // 아뮤소 89회 넣어야함

  {
    id: "vj-event-45",
    title: "찾아가는 락케스 50회",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_45.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_45.png",
      "/images/workImages/45.SOOP_RCS/SOOP_RCS_01.png",
      "/images/workImages/45.SOOP_RCS/SOOP_RCS_02.png",
    ],
    date: "2024-12-19",
    location: "Seoul, Republic of Korea",
    description:
      "SOOP의 방송, 찾아가는 락케스 50회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-44",
    title: "2024 제천시장배 E-스포츠 대회",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_44.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_44.png",
      "/images/workImages/44.JCE-sports/JCE-sports_01.png",
      "/images/workImages/44.JCE-sports/JCE-sports_02.png",
      "/images/workImages/44.JCE-sports/JCE-sports_03.png",
      "/images/workImages/44.JCE-sports/JCE-sports_04.png",
      "/images/workImages/44.JCE-sports/JCE-sports_05.png",
    ],
    date: "2024-11-30 ~ 2025-12-01",
    location: "Jecheon, Republic of Korea",
    description: "2024 제천시장배 E-스포츠 대회 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-43",
    title: "제 88회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_43.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_43.png",
      "/images/workImages/43.AMS_88/AMS_88_01.png",
      "/images/workImages/43.AMS_88/AMS_88_02.png",
      "/images/workImages/43.AMS_88/AMS_88_03.png",
    ],
    date: "2024-11-25",
    location: "Seoul, Republic of Korea",
    description:
      "SOOP의 공식 음악 방송, 아뮤소 88회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-42",
    title: "SOOP 캠퍼스 음악대전",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_42.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_42.png",
      "/images/workImages/42.SOOP_MC/SOOP_MC_01.png",
      "/images/workImages/42.SOOP_MC/SOOP_MC_02.png",
    ],
    date: "2024-11-21",
    location: "Seoul, Republic of Korea",
    description:
      "SOOP의 방송 캠퍼스 음악대전 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-41",
    title: "NRD-K ph.1 설비반입식",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_41.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_41.png",
      "/images/workImages/41.NRD-K/NRD-K_01.png",
    ],
    date: "2024-11-18",
    location: "Gyeonggi, Republic of Korea",
    description:
      "삼성 반도체 NRD-K ph.1 설비반입식 디자인, 세리모니 맵핑 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design", "Projection Mapping"],
  },
  {
    id: "vj-event-40",
    title: "간호법 제정 축하 기념대회",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_40.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_40.png",
      "/images/workImages/40.GHHHHS/GHHHHS_01.png",
      "/images/workImages/40.GHHHHS/GHHHHS_02.png",
      "/images/workImages/40.GHHHHS/GHHHHS_03.png",
      "/images/workImages/40.GHHHHS/GHHHHS_04.png",
    ],
    date: "2024-11-12",
    location: "Seoul, Republic of Korea",
    description:
      "간호협회 간호법 제정 100년 행사 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-39",
    title: "2024 경기 게임 커넥트",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_39.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_39.png",
      "/images/workImages/39.GGGC2024/GGGC2024_01.png",
      "/images/workImages/39.GGGC2024/GGGC2024_02.png",
      "/images/workImages/39.GGGC2024/GGGC2024_03.png",
      "/images/workImages/39.GGGC2024/GGGC2024_04.png",
    ],
    date: "2024-10-30 ~ 31",
    location: "Gyeonggi, Republic of Korea",
    description: "2024 경기 게임 커넥트 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-38",
    title: "제 87회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_38.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_38.png",
      "/images/workImages/38.AMS_87/AMS_87_01.png",
      "/images/workImages/38.AMS_87/AMS_87_02.png",
    ],
    date: "2024-10-25",
    location: "Seoul, Republic of Korea",
    description:
      "SOOP의 공식 음악 방송, 아뮤소 86회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-37",
    title: "2024 전기공사협회 행사",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_37.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_37.png",
      "/images/workImages/37.PCJRRS/PCJRRS_01.png",
      "/images/workImages/37.PCJRRS/PCJRRS_02.png",
      "/images/workImages/37.PCJRRS/PCJRRS_03.png",
      "/images/workImages/37.PCJRRS/PCJRRS_04.png",
      "/images/workImages/37.PCJRRS/PCJRRS_05.png",
    ],
    date: "2024-10-23",
    location: "Pyeongchang, Republic of Korea",
    description:
      "평창에서 진행한 전기공사협회 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-36",
    title: "MBC every1 에너미X",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_36.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_36.png",
      "/images/workImages/36.EnemyX/EnemyX_01.png",
      "/images/workImages/36.EnemyX/EnemyX_02.png",
      "/images/workImages/36.EnemyX/EnemyX_03.png",
      "/images/workImages/36.EnemyX/EnemyX_04.png",
      "/images/workImages/36.EnemyX/EnemyX_05.png",
    ],
    date: "2024-10-18",
    location: "Seoul, Republic of Korea",
    description: "MBC every1의 방송 에너미X VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-35",
    title: "채널 A 리브랜딩행사",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_35.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_35.png",
      "/images/workImages/35.CHA_REbrand/CHA_REbrand_01.png",
      "/images/workImages/35.CHA_REbrand/CHA_REbrand_02.png",
      "/images/workImages/35.CHA_REbrand/CHA_REbrand_03.png",
    ],
    date: "2024-10-14",
    location: "Seoul, Republic of Korea",
    description: "방송사 채널 A 리브랜딩행사 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-34",
    title: "퇴근 후 FC모바일 나는 직장인이다",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_34.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_34.png",
      "/images/workImages/34.YED_FCM/YED_FCM_01.png",
      "/images/workImages/34.YED_FCM/YED_FCM_02.png",
      "/images/workImages/34.YED_FCM/YED_FCM_03.png",
      "/images/workImages/34.YED_FCM/YED_FCM_04.png",
      "/images/workImages/34.YED_FCM/YED_FCM_05.png",
    ],
    date: "2024-10-12 ~ 13",
    location: "Seoul, Republic of Korea",
    description: "SOOP의 방송 퇴근 후 FC 모바일 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-33",
    title: "넷플릭스 전,란 제작발표회",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_33.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_33.png",
      "/images/workImages/33.NF_JR/NF_JR_01.png",
      "/images/workImages/33.NF_JR/NF_JR_02.png",
      "/images/workImages/33.NF_JR/NF_JR_03.png",
    ],
    date: "2024-10-09",
    location: "Seoul, Republic of Korea",
    description: "넷플릭스 영화 전,란 제작발표회 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-32",
    title: "2024 세계 꽃문화관광축제 호수야 놀자",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_32.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_32.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_01.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_02.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_03.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_04.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_05.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_06.png",
      "/images/workImages/32.GY_Flower/GY_Flowe_07.png",
    ],
    date: "2024-10-02 ~ 06",
    location: "Goyang, Republic of Korea",
    description:
      "고양의 세계 꽃 문화 관광축제 디자인 및 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-31",
    title: "제 85회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_31.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_31.png",
      "/images/workImages/31.AMS_85/AMS_85_01.png",
      "/images/workImages/31.AMS_85/AMS_85_02.png",
    ],
    date: "2024-09-25",
    location: "Seoul, Republic of Korea",
    description:
      "아프리카TV의 공식 음악 방송, 아프리카 TV 뮤지션을 소개합니다 85회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-30",
    title: "광화문 기후공명",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_30.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_30.png",
      "/images/workImages/30.GHM_GHGM/GHM_GHGM_01.png",
      "/images/workImages/30.GHM_GHGM/GHM_GHGM_02.png",
      "/images/workImages/30.GHM_GHGM/GHM_GHGM_03.png",
      "/images/workImages/30.GHM_GHGM/GHM_GHGM_04.png",
    ],
    date: "2024-09-11",
    location: "Seoul, Republic of Korea",
    description: "틱톡 행사 광화문 기후공명VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-29",
    title: "최현우의 매직매치 2화",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_29.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_29.png",
      "/images/workImages/29.MAGICMATCH02/MAGICMATCH02_01.png",
      "/images/workImages/29.MAGICMATCH02/MAGICMATCH02_02.png",
      "/images/workImages/29.MAGICMATCH02/MAGICMATCH02_03.png",
      "/images/workImages/29.MAGICMATCH02/MAGICMATCH02_04.png",
    ],
    date: "2024-09-06",
    location: "Seoul, Republic of Korea",
    description:
      "SOOP의 방송 매직매치 2화 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-28",
    title: "CBS 가을 음악회",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_28.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_28.png",
      "/images/workImages/28.CBS_FOOL/CBS_FOOL_01.png",
      "/images/workImages/28.CBS_FOOL/CBS_FOOL_02.png",
      "/images/workImages/28.CBS_FOOL/CBS_FOOL_03.png",
    ],
    date: "2024-09-05",
    location: "Cheongju, Republic of Korea",
    description: "청주 CBS 가을 음악회 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-27",
    title: "2024 직지문화축제",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_27.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_27.png",
      "/images/workImages/27.JICJI/JICJI_01.png",
      "/images/workImages/27.JICJI/JICJI_02.png",
      "/images/workImages/27.JICJI/JICJI_03.png",
      "/images/workImages/27.JICJI/JICJI_04.png",
      "/images/workImages/27.JICJI/JICJI_05.png",
    ],
    date: "2024-09-04",
    location: "Cheongju, Republic of Korea",
    description: "청주 직지 문화축제 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-26",
    title: "제 84회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_26.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_26.png",
      "/images/workImages/26.AMS_84/AMS_84_01.png",
      "/images/workImages/26.AMS_84/AMS_84_02.png",
      "/images/workImages/26.AMS_84/AMS_84_03.png",
    ],
    date: "2024-08-25",
    location: "Seoul, Republic of Korea",
    description:
      "아프리카TV의 공식 음악 방송, 아프리카 TV 뮤지션을 소개합니다 84회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-25",
    title: "제 3회 벨기에 문화축제 기념 콘서트",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_25.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_25.png",
      "/images/workImages/25.BGECON/BGECON_01.png",
      "/images/workImages/25.BGECON/BGECON_02.png",
      "/images/workImages/25.BGECON/BGECON_03.png",
      "/images/workImages/25.BGECON/BGECON_04.png",
      "/images/workImages/25.BGECON/BGECON_05.png",
      "/images/workImages/25.BGECON/BGECON_06.png",
      "/images/workImages/25.BGECON/BGECON_07.png",
      "/images/workImages/25.BGECON/BGECON_08.png",
      "/images/workImages/25.BGECON/BGECON_09.png",
    ],
    date: "2024-08-24",
    location: "Incheon, Republic of Korea",
    description:
      "벨기에 문화축제 기념 콘서트 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-24",
    title: "마린유겐트 개소식 / 심포지엄",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_24.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_24.png",
      "/images/workImages/24.UGENT/UGENT_01.png",
      "/images/workImages/24.UGENT/UGENT_02.png",
      "/images/workImages/24.UGENT/UGENT_03.png",
      "/images/workImages/24.UGENT/UGENT_04.png",
      "/images/workImages/24.UGENT/UGENT_05.png",
    ],
    date: "2024-08-22",
    location: "Incheon, Republic of Korea",
    description:
      "인천에 위치한 마린유겐트 대학교 행사 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-23",
    title: "서귀포문화제야행 - 스토리텔러",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_23.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_23.png",
      "/images/workImages/23.SGPMHJ/SGPMHJ_01.png",
      "/images/workImages/23.SGPMHJ/SGPMHJ_02.png",
      "/images/workImages/23.SGPMHJ/SGPMHJ_03.png",
      "/images/workImages/23.SGPMHJ/SGPMHJ_04.png",
      "/images/workImages/23.SGPMHJ/SGPMHJ_05.png",
    ],
    date: "2024-08-14",
    location: "Jeju, Republic of Korea",
    description:
      "한국국제스토리텔러협회에서 제주도에서 공연한 이야기 백그라운드 작업을 진행했습니다.",
    tasks: ["VJ Design"],
  },
  {
    id: "vj-event-22",
    title: "수진 월드투어_싱가포르",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_22.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_22.png",
      "/images/workImages/22.SJ_Singa/SJ_Singa_01.png",
      "/images/workImages/22.SJ_Singa/SJ_Singa_02.png",
      "/images/workImages/22.SJ_Singa/SJ_Singa_03.png",
      "/images/workImages/22.SJ_Singa/SJ_Singa_04.png",
      "/images/workImages/22.SJ_Singa/SJ_Singa_05.png",
    ],
    date: "2024-08-08",
    location: "Singapore",
    description: "가수 수진의 싱가포르 콘서트 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-21",
    title: "Alyglo 첫 출하 기념 행사",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_21.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_21.png",
      "/images/workImages/21.Alyglo/Alyglo_01.png",
      "/images/workImages/21.Alyglo/Alyglo_02.png",
      "/images/workImages/21.Alyglo/Alyglo_03.png",
      "/images/workImages/21.Alyglo/Alyglo_04.png",
      "/images/workImages/21.Alyglo/Alyglo_05.png",
    ],
    date: "2024-07-08",
    location: "Cheongju, Republic of Korea",
    description:
      "GC녹십자의 알리 글로 첫 출하 기념 행사 디자인, 세레모니 이펙트 영상 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-20",
    title: "제 82회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_20.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_20.png",
      "/images/workImages/20.AMS_82/AMS_82_01.png",
      "/images/workImages/20.AMS_82/AMS_82_02.png",
      "/images/workImages/20.AMS_82/AMS_82_03.png",
    ],
    date: "2024-06-25",
    location: "Seoul, Republic of Korea",
    description:
      "아프리카TV의 공식 음악 방송, 아프리카 TV 뮤지션을 소개합니다 82회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-19",
    title: "2024 서영은 콘서트",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_19.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_19.png",
      "/images/workImages/19.SYECON/SYECON_01.png",
      "/images/workImages/19.SYECON/SYECON_02.png",
      "/images/workImages/19.SYECON/SYECON_03.png",
      "/images/workImages/19.SYECON/SYECON_04.png",
      "/images/workImages/19.SYECON/SYECON_05.png",
      "/images/workImages/19.SYECON/SYECON_06.png",
    ],
    date: "2024-06-15",
    location: "Seoul, Republic of Korea",
    description: "서영은 콘서트 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-18",
    title: "2024 심수봉 콘서트_대전",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_18.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_18.png",
      "/images/workImages/18.SSBCON/SSBCON_01.png",
      "/images/workImages/18.SSBCON/SSBCON_02.png",
      "/images/workImages/18.SSBCON/SSBCON_03.png",
      "/images/workImages/18.SSBCON/SSBCON_04.png",
      "/images/workImages/18.SSBCON/SSBCON_05.png",
      "/images/workImages/18.SSBCON/SSBCON_06.png",
      "/images/workImages/18.SSBCON/SSBCON_07.png",
    ],
    date: "2024-06-09",
    location: "Daejeon, Republic of Korea",
    description:
      "심수봉 전국 투어의 대전을 담당하여 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-17",
    title: "제 9회 BNI Korea",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_17.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_17.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_01.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_02.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_03.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_04.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_05.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_06.png",
      "/images/workImages/17.BNIKOREA/BNIKOREA_07.png",
    ],
    date: "2024-06-03",
    location: "Seoul, Republic of Korea",
    description:
      "2024년도 BNI KOREA 행사에서 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-16",
    title: "제 81회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_16.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_16.png",
      "/images/workImages/16.AMS_81/AMS_81_01.png",
      "/images/workImages/16.AMS_81/AMS_81_02.png",
      "/images/workImages/16.AMS_81/AMS_81_03.png",
      "/images/workImages/16.AMS_81/AMS_81_04.png",
    ],
    date: "2024-05-25",
    location: "Seoul, Republic of Korea",
    description:
      "아프리카TV의 공식 음악 방송, 아프리카 TV 뮤지션을 소개합니다 81회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-15",
    title: "NewJeans CF",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_15.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_15.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_01.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_02.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_03.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_04.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_05.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_06.png",
      "/images/workImages/15.NewJeansCF/NewJeansCF_07.png",
    ],
    date: "2024-05-02",
    location: "Hwaseong, Republic of Korea",
    description: "한국 관광공사의 CF 촬영 현장 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-14",
    title: "제 80회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_14.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_14.png",
      "/images/workImages/14.AMS_80/AMS_80_01.png",
      "/images/workImages/14.AMS_80/AMS_80_02.png",
      "/images/workImages/14.AMS_80/AMS_80_03.png",
      "/images/workImages/14.AMS_80/AMS_80_04.png",
    ],
    date: "2024-04-25",
    location: "Seoul, Republic of Korea",
    description:
      "아프리카TV의 공식 음악 방송, 아프리카 TV 뮤지션을 소개합니다 80회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-13",
    title: "LIGHTSUM 2024 SUM&GREET",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_13.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_13.png",
      "/images/workImages/13.LIGHTSUM/LIGHTSUM_01.png",
      "/images/workImages/13.LIGHTSUM/LIGHTSUM_02.png",
      "/images/workImages/13.LIGHTSUM/LIGHTSUM_03.png",
      "/images/workImages/13.LIGHTSUM/LIGHTSUM_04.png",
      "/images/workImages/13.LIGHTSUM/LIGHTSUM_05.png",
    ],
    date: "2024-04-20",
    location: "Seoul, Republic of Korea",
    description:
      "큐브 엔터테인먼트의 LIGHTSUM 2024 SUM&GREET 공연 디자인을 진행했습니다.",
    tasks: ["Perfomance Design"],
  },
  {
    id: "vj-event-12",
    title: "2024 국민의 선택",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_12.png",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_12.png",
      "/images/workImages/12.KMEST/KMEST_01.png",
      "/images/workImages/12.KMEST/KMEST_02.png",
      "/images/workImages/12.KMEST/KMEST_03.png",
      "/images/workImages/12.KMEST/KMEST_04.png",
      "/images/workImages/12.KMEST/KMEST_05.png",
      "/images/workImages/12.KMEST/KMEST_06.png",
      "/images/workImages/12.KMEST/KMEST_07.png",
    ],
    date: "2024-04-10",
    location: "Seoul, Republic of Korea",
    description:
      "제22대 국회의원 선거에 대한 SBS의 선거방송 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-11",
    title: "제 79회 아뮤소",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_11.jpg",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_11.jpg",
      "/images/workImages/11.AMS/AMS_01.png",
      "/images/workImages/11.AMS/AMS_02.png",
      "/images/workImages/11.AMS/AMS_03.png",
    ],
    date: "2024-03-25",
    location: "Seoul, Republic of Korea",
    description:
      "아프리카TV의 공식 음악 방송, 아프리카 TV 뮤지션을 소개합니다 79회 공연 소스 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-10",
    title: "제 53회 hy대회",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_10.jpg",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_10.jpg",
      "/images/workImages/10.hy/hy_01.png",
      "/images/workImages/10.hy/hy_02.png",
      "/images/workImages/10.hy/hy_03.png",
      "/images/workImages/10.hy/hy_04.png",
    ],
    date: "2024-03-21",
    location: "Gwangyang, Republic of Korea",
    description:
      "한국 야구르트 전국 행사중, 광양을 담당하여 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-9",
    title: "캐스트원 창립 33주년 기념식",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_09.jpg",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_09.jpg",
      "/images/workImages/09.CastWin/CastWin_01.png",
      "/images/workImages/09.CastWin/CastWin_02.png",
      "/images/workImages/09.CastWin/CastWin_03.png",
      "/images/workImages/09.CastWin/CastWin_04.png",
    ],
    date: "2024-02-01",
    location: "Seoul, Republic of Korea",
    description: "캐스트원 창립 33주년 기념식 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-8",
    title: "Richsister Queen Awards",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_08.jpg",
    subImages: [
      "/images/VJ_Title_Image/VJ_title_08.jpg",
      "/images/workImages/08.RSQueen/RSQueen_01.png",
    ],
    date: "2023-11-17",
    location: "Seoul, Republic of Korea",
    description:
      "부자언니 유수진의 행사 Richsister Queen Awards 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-7",
    title: "제4회 돌아온 안흥진성 태안가곡제",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_07.jpg",
    subImages: [
      "/images/workImages/07.TASing/TASing_01.png",
      "/images/workImages/07.TASing/TASing_02.png",
      "/images/workImages/07.TASing/TASing_03.png",
    ],
    date: "2023-11-02",
    location: "Taean, Republic of Korea",
    description:
      "돌아온 안흥진성을 기념으로 한 가곡제 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-6",
    title: "제 3회 광양 K-POP Festival",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_06.jpg",
    subImages: [
      "/images/workImages/06.KYKpop/KYKpop_01.png",
      "/images/workImages/06.KYKpop/KYKpop_02.png",
      "/images/workImages/06.KYKpop/KYKpop_03.png",
      "/images/workImages/06.KYKpop/KYKpop_04.png",
    ],
    date: "2023-10-20 ~ 2023-10-21",
    location: "Gwangyang, Republic of Korea",
    description: "제 3회 광양 K-POP Festival VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-5",
    title: "관세청 비전 선포식",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_05.jpg",
    subImages: [
      "/images/workImages/05.GscVision/GscVision_01.png",
      "/images/workImages/05.GscVision/GscVision_02.png",
      "/images/workImages/05.GscVision/GscVision_03.png",
      "/images/workImages/05.GscVision/GscVision_04.png",
    ],
    date: "2023-10-05",
    location: "Cheonan, Republic of Korea",
    description: "2023 관세청 비전 선포식 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-4",
    title: "11th TPO GENERAL ASSEMBLY",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_04.jpg",
    subImages: [
      "/images/workImages/04.TPO/TPO_01.png",
      "/images/workImages/04.TPO/TPO_02.png",
      "/images/workImages/04.TPO/TPO_03.png",
      "/images/workImages/04.TPO/TPO_04.png",
      "/images/workImages/04.TPO/TPO_05.png",
      "/images/workImages/04.TPO/TPO_06.png",
      "/images/workImages/04.TPO/TPO_07.png",
    ],
    date: "2023-09-12",
    location: "Jeonju, Republic of Korea",
    description:
      "국제회의 TOP GENERAL ASSEMBLY 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-3",
    title: "2023 퀴즈 온 코리아",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_03.jpg",
    subImages: [
      "/images/workImages/03.QuizeOnKorea/QuizeOnKorea_01.png",
      "/images/workImages/03.QuizeOnKorea/QuizeOnKorea_02.png",
      "/images/workImages/03.QuizeOnKorea/QuizeOnKorea_03.png",
      "/images/workImages/03.QuizeOnKorea/QuizeOnKorea_04.png",
      "/images/workImages/03.QuizeOnKorea/QuizeOnKorea_05.png",
    ],
    date: "2023-09-10",
    location: "Seoul, Republic of Korea",
    description: "KBS의 방송 퀴즈 온 코리아 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator"],
  },
  {
    id: "vj-event-2",
    title: "ZIC BRAND DAY",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_02.jpg",
    subImages: [
      "/images/workImages/02.ZIC/ZIC_01.png",
      "/images/workImages/02.ZIC/ZIC_02.png",
      "/images/workImages/02.ZIC/ZIC_03.png",
      "/images/workImages/02.ZIC/ZIC_04.png",
    ],
    date: "2023-09-04",
    location: "Seoul, Republic of Korea",
    description:
      "SK엔무브의 지크 브랜드데이 행사 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
  {
    id: "vj-event-1",
    title: "Indo-Pacific Economic Framework for Prosperity (IPEF)",
    category: "Visual Jockey",
    image: "/images/VJ_Title_Image/VJ_title_01.jpg",
    subImages: [
      "/images/workImages/01.IPEF/IPEF_01.png",
      "/images/workImages/01.IPEF/IPEF_02.png",
      "/images/workImages/01.IPEF/IPEF_03.png",
      "/images/workImages/01.IPEF/IPEF_04.png",
      "/images/workImages/01.IPEF/IPEF_05.png",
      "/images/workImages/01.IPEF/IPEF_06.png",
      "/images/workImages/01.IPEF/IPEF_07.png",
    ],
    date: "2023-07-14",
    location: "Busan, Republic of Korea",
    description:
      "IPEF 국제회의에서 행사 디자인 및 VJ 오퍼레이팅을 진행했습니다.",
    tasks: ["VJ Operator", "VJ Design"],
  },
];
