<template>
  <header :class="{ 'scrolled-header': isScrolled || !isHome }" class="header">
    <div class="header-container">
      <div class="logo">
        <router-link to="/">
          <img src="/images/Achacha_Logo_Text_White.png" alt="Logo" />
        </router-link>
      </div>
      <nav class="navigation">
        <ul>
          <li>
            <router-link to="/" :class="{ active: route.path === '/' }"
              >HOME</router-link
            >
          </li>
          <li>
            <router-link
              to="/about"
              :class="{ active: route.path === '/about' }"
              >ABOUT</router-link
            >
          </li>
          <li>
            <router-link
              to="/works"
              :class="{ active: route.path === '/works' }"
              >WORKS</router-link
            >
          </li>
          <li>
            <router-link
              to="/contact"
              :class="{ active: route.path === '/contact' }"
              >CONTACT</router-link
            >
          </li>
        </ul>
      </nav>
      <div class="hamburger" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <nav class="mobile-navigation" v-if="isMobileMenuOpen">
      <ul>
        <li>
          <router-link
            @click="closeMenu"
            to="/"
            :class="{ active: route.path === '/' }"
            >HOME</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMenu"
            to="/about"
            :class="{ active: route.path === '/about' }"
            >ABOUT</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMenu"
            to="/works"
            :class="{ active: route.path === '/works' }"
            >WORKS</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMenu"
            to="/contact"
            :class="{ active: route.path === '/contact' }"
            >CONTACT</router-link
          >
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, watchEffect, onMounted, onUnmounted } from "vue";

export default {
  name: "Header",
  setup() {
    const route = useRoute();
    const isScrolled = ref(false);
    const isHome = ref(route.path === "/");
    const isMobileMenuOpen = ref(false); // ✅ 모바일 메뉴 상태 추가

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 50;
    };

    const toggleMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    };

    const closeMenu = () => {
      isMobileMenuOpen.value = false;
    };

    watchEffect(() => {
      isHome.value = route.path === "/";
    });

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      isScrolled,
      isHome,
      route,
      isMobileMenuOpen,
      toggleMenu,
      closeMenu,
    };
  },
};
</script>

<style scoped>
/* 기본 헤더 스타일 */
.header {
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

/* 스크롤 시 또는 홈이 아닐 때 변경되는 헤더 스타일 */
.scrolled-header {
  background: #383838; /* 스크롤 시 적용될 배경색 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 살짝 그림자 추가 */
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  width: 10vw;
  max-width: 150px;
  min-width: 80px;
}

/* 데스크탑 네비게이션 */
.navigation ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin-left: 2rem;
}

/* 기본 네비게이션 링크 스타일 */
.navigation a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease-in-out;
}

/* 🔥 Hover 효과 (마우스를 올릴 때) */
.navigation a:hover {
  color: #ff6600; /* 호버 시 색상 변경 */
}

/* 🔥 Active 효과 (현재 페이지) */
.navigation a.active {
  color: #ff6600;
  font-weight: 700;
  border-bottom: 2px solid #ff6600; /* 활성화된 메뉴에 밑줄 */
  padding-bottom: 4px;
}

/* 모바일 햄버거 버튼 */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background: #fff;
  margin-bottom: 4px;
  border-radius: 2px;
}

/* 모바일 네비게이션 */
.mobile-navigation {
  display: none;
  background: #fff;
  padding: 1rem;
}

.mobile-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-navigation li {
  margin: 1rem 0;
}

/* 모바일 메뉴 스타일 */
.mobile-navigation a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  transition: color 0.3s;
}

/* 🔥 모바일 메뉴 - Active 상태 */
.mobile-navigation a.active {
  color: #ff6600;
  font-weight: 700;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .navigation {
    display: none;
  }
  .hamburger {
    display: flex;
  }
  .mobile-navigation {
    display: block;
  }

  .logo img {
    width: 90px;
  }
}
</style>
