<!-- src/components/works/WorksHero.vue -->
<template>
  <section class="works-hero">
    <div class="overlay"></div>
    <div class="hero-content">
      <h1>Portfolio</h1>
      <p>아차차가 진행한 프로젝트를 확인해보세요.</p>
    </div>
  </section>
</template>

<style scoped>
.works-hero {
  position: relative;
  width: 100%;
  height: 300px;
  /* background: url("/images/works-hero.jpg") center/cover no-repeat; */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.hero-content p {
  font-size: 1.2rem;
}
</style>
