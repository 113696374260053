<!-- src/components/works/WorksFilter.vue -->
<template>
  <div class="works-filter">
    <button
      v-for="category in categories"
      :key="category"
      @click="filterCategory(category)"
      :class="{ active: selectedCategory === category }"
    >
      {{ category }}
    </button>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "WorksFilter",
  setup(_, { emit }) {
    const categories = ["All", "Visual Jockey", "Media Interaction"];
    const selectedCategory = ref("All");

    const filterCategory = (category) => {
      selectedCategory.value = category;
      emit("filter", category); // 부모 컴포넌트(Works.vue)에 선택된 카테고리 전달
    };

    return { categories, selectedCategory, filterCategory };
  },
};
</script>

<style scoped>
.works-filter {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 30px 0;
}

.works-filter button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  background: #444;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.works-filter button:hover,
.works-filter button.active {
  background: #ff6600;
}
</style>
