<template>
  <div class="map-container">
    <iframe
      width="100%"
      height="400"
      frameborder="0"
      style="border: 0"
      :src="mapUrl"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "ContactMap",
  computed: {
    mapUrl() {
      // 환경 변수가 존재하지 않을 경우 디폴트 값 추가 (예방 코드)
      const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      if (!apiKey) {
        console.error("❌ Google Maps API 키가 설정되지 않았습니다.");
        return "";
      }

      const location =
        "20+Nambusunhwan-ro+226-gil,+Gwanak-gu,+Seoul,+Republic+of+Korea";
      return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${location}`;
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
}
</style>
