<template>
  <div class="contact-form">
    <h2>문의하기</h2>
    <p>궁금한 점이 있으신가요? 아래 양식을 작성해 주세요.</p>
    <form @submit.prevent="handleSubmit">
      <div class="input-group">
        <label for="name">이름</label>
        <input
          type="text"
          id="name"
          v-model="form.name"
          required
          placeholder="이름을 입력하세요"
        />
      </div>
      <div class="input-group">
        <label for="email">이메일</label>
        <input
          type="email"
          id="email"
          v-model="form.email"
          required
          placeholder="이메일을 입력하세요"
        />
      </div>
      <div class="input-group">
        <label for="message">메시지</label>
        <textarea
          id="message"
          v-model="form.message"
          required
          placeholder="문의 내용을 입력하세요"
        ></textarea>
      </div>
      <button type="submit" class="submit-btn">보내기</button>
    </form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      // EmailJS 설정 값 (환경변수로 관리하는 것이 좋습니다)

      const serviceID = process.env.VUE_APP_EMAILJS_SERVICE_ID; // EmailJS 대시보드에서 확인
      const templateID = process.env.VUE_APP_EMAILJS_TEMPLATE_ID; // EmailJS 대시보드에서 확인
      const userID = process.env.VUE_APP_EMAILJS_USER_ID; // EmailJS Public Key (사용자 인증 키)

      emailjs
        .send(serviceID, templateID, this.form, userID)
        .then((response) => {
          console.log("Email sent successfully", response); // 추가하여 사용하거나
          alert("문의가 정상적으로 접수되었습니다!");
          // 폼 초기화
          this.form.name = "";
          this.form.email = "";
          this.form.message = "";
        })
        .catch((error) => {
          console.error("EmailJS 전송 오류:", error);
          alert("문의 전송에 실패했습니다. 다시 시도해 주세요.");
        });
    },
  },
};
</script>

<style scoped>
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: #ff6600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-btn:hover {
  background: #cc5200;
}
</style>
