<template>
  <section class="portfolio-section">
    <!-- "Portfolio" 섹션 제목 (limit prop이 있을 때만 표시) -->
    <h2 class="section-title" v-if="limit">Portfolio</h2>

    <div class="portfolio-grid">
      <PortfolioItem
        v-for="(item, index) in displayedItems"
        :key="index"
        :item="item"
      />
    </div>

    <!-- "더 많은 포트폴리오 보기" 버튼 (limit이 있을 때만) -->
    <div class="button-container" v-if="showViewMore">
      <router-link to="/works" class="view-more"> MORE </router-link>
    </div>
  </section>
</template>

<script>
import PortfolioItem from "./PortfolioItem.vue";
import portfolioData from "@/data/portfolioData.js";
import { computed, toRefs, ref, onMounted, onUnmounted } from "vue";

export default {
  name: "PortfolioGrid",
  components: { PortfolioItem },
  props: {
    selectedCategory: {
      type: String,
      default: "All",
    },
    limit: {
      type: Number,
      default: null, // Home.vue에서만 제한된 개수(예: 12개)를 전달
    },
  },
  setup(props) {
    const { selectedCategory, limit } = toRefs(props);
    const windowWidth = ref(window.innerWidth);

    // 반응형을 위해 창 크기 감지
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    // 선택된 카테고리에 따른 필터링
    const filteredItems = computed(() => {
      if (!selectedCategory.value || selectedCategory.value === "All") {
        return portfolioData;
      }
      return portfolioData.filter(
        (item) => item.category === selectedCategory.value
      );
    });

    // 모바일에서는 9개, 데스크탑에서는 전달된 limit을 사용하도록 결정
    const effectiveLimit = computed(() => {
      if (limit.value) {
        return windowWidth.value <= 768
          ? Math.min(limit.value, 9)
          : limit.value;
      }
      return null;
    });

    const displayedItems = computed(() => {
      return effectiveLimit.value
        ? filteredItems.value.slice(0, effectiveLimit.value)
        : filteredItems.value;
    });

    const showViewMore = computed(() => {
      return (
        effectiveLimit.value &&
        filteredItems.value.length > effectiveLimit.value
      );
    });

    return { displayedItems, showViewMore };
  },
};
</script>

<style scoped>
.portfolio-section {
  padding: 80px 20px;
  text-align: center;
  background-color: #000;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 40px;
  color: #fff;
}

/* 기본 그리드: auto-fit 사용 */
.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* 모바일 (최대 768px)에서는 3열 그리드 강제 적용 */
@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.view-more {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: #ff6600;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}

.view-more:hover {
  background: #cc5200;
}
</style>
