<template>
  <section class="about-section">
    <!-- 1️⃣ About Intro -->
    <div class="about-intro fade-in">
      <h2>아차차는 창의적인 솔루션을 제공합니다.</h2>
      <p>
        주식회사 아차차는 VJ(Visual Jockey) 퍼포먼스와 소프트웨어 개발을
        기반으로 미디어 인터랙션 플랫폼 영역으로 사업을 확장하며 창의적인
        솔루션을 제공하고 있습니다.
      </p>
    </div>

    <!-- 2️⃣ 미션 & 비전 (아이콘 포함) -->
    <div class="about-mission fade-in">
      <h2>미션 & 비전</h2>
      <div class="mission-list">
        <div class="mission-item">
          <font-awesome-icon icon="lightbulb" class="icon" />
          <h3>창의성과 기술</h3>
          <p>새로운 경험과 가치를 창출하는 창의적 솔루션</p>
        </div>
        <div class="mission-item">
          <font-awesome-icon icon="rocket" class="icon" />
          <h3>혁신 주도</h3>
          <p>경험의 경계를 허물고 미디어와 기술의 미래를 개척</p>
        </div>
        <div class="mission-item">
          <font-awesome-icon icon="chart-line" class="icon" />
          <h3>플랫폼 성장</h3>
          <p>미디어 인터랙션 플랫폼의 대명사로 도약</p>
        </div>
      </div>
    </div>

    <!-- 3️⃣ 성장 전략 (Step 스타일 유지) -->
    <div class="about-strategy fade-in">
      <h2>성장 전략</h2>
      <div class="strategy-steps">
        <div class="step">
          <h4 class="step-title">Step.1</h4>
          <h3>창의적 솔루션 개발</h3>
          <p>차별화된 서비스를 통해 경쟁 우위를 확보</p>
        </div>
        <div class="step">
          <h4 class="step-title">Step.2</h4>
          <h3>지속적인 연구개발 (R&D)</h3>
          <p>최신 기술과 미디어 인터랙션을 융합</p>
        </div>
        <div class="step">
          <h4 class="step-title">Step.3</h4>
          <h3>맞춤형 솔루션 제공</h3>
          <p>고객 만족도를 극대화하고 지속 성장</p>
        </div>
      </div>
    </div>

    <!-- 4️⃣ 주요 프로젝트 (이미지 크기 및 정렬 수정) -->
    <div class="about-projects fade-in">
      <h2>주요 프로젝트</h2>
      <div class="projects-container">
        <div class="project">
          <img src="/images/Carousel_Img01.png" alt="Genesis JK EV Project" />
          <h3>제네시스 JK EV F/L 런칭 프로모션</h3>
          <p>
            미디어와 결합된 소프트웨어 개발을 통해 새로운 경험을 제공하며,
            브랜드와 사용자를 연결하는 인터랙션을 창출했습니다.
          </p>
        </div>
        <div class="project">
          <img src="/images/Carousel_Img03.png" alt="GilTeo Project" />
          <h3>길터(샤로수길 어플리케이션)</h3>
          <p>
            사용자 경험 중심의 위치 기반 솔루션을 통해 지역경제와 디지털
            플랫폼의 새로운 가능성을 제시하고 있습니다.
          </p>
        </div>
      </div>
    </div>

    <!-- 5️⃣ 회사 소개서 다운로드 -->
    <div class="download-section fade-in">
      <a href="/docs/ACHACHA.inc_PR_2025.pdf" download class="download-btn">
        <font-awesome-icon icon="file-pdf" />
        회사 소개서 다운로드
      </a>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLightbulb,
  faRocket,
  faChartLine,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

library.add(faLightbulb, faRocket, faChartLine, faFilePdf);

export default {
  name: "AboutSection",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
/* 전체 섹션 */
.about-section {
  width: 100%;
  padding: 60px 0;
  text-align: center;
  background-color: #f8f9fa;
  background-image: url("../assets/AboutSection_Img.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

/* About Intro */
.about-intro {
  max-width: 900px;
  margin: 0 auto;
  padding: 60px 20px;
}
.about-intro h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.about-intro p {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 40px;
  line-height: 1.5;
}

/* 미션 & 비전 */
.about-mission {
  padding: 40px 0;
}
.mission-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.mission-item {
  background: #fff;
  padding: 15px; /* 기존 20px → 15px */
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  max-width: 32%;
  text-align: center;
  box-sizing: border-box;
}
.mission-item h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 8px; /* 약간 줄임 */
  color: #333;
}
.mission-item p {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

/* 성장 전략 */
.about-strategy {
  padding: 40px 0;
}
.strategy-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.step {
  background: #fff;
  padding: 15px; /* 기존 20px → 15px */
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
  max-width: 32%;
  text-align: center;
  box-sizing: border-box;
}
.step-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff6600;
  margin-bottom: 8px;
}
.step h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.step p {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

/* 주요 프로젝트 */
.about-projects {
  padding: 40px 0;
}
.projects-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}
.project {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  text-align: center;
  box-sizing: border-box;
}
.project img {
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: cover;
  border-radius: 10px;
}
.project h3 {
  font-size: 1.5rem;
  margin-top: 15px;
}
.project p {
  font-size: 1rem;
  color: #666;
}

/* 회사 소개서 다운로드 */
.download-section {
  text-align: center;
  padding: 30px 0;
}
.download-btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: #ff6600;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}
.download-btn:hover {
  background: #cc5200;
}

/* 반응형 (태블릿 및 모바일) */
@media (max-width: 1024px) {
  .about-intro {
    padding: 50px 15px;
  }
  .about-intro h2 {
    font-size: 2.5rem;
  }
  .about-intro p {
    font-size: 1.3rem;
    margin-bottom: 30px;
  }

  .mission-list,
  .strategy-steps {
    flex-direction: column;
    gap: 20px;
    padding: 0 15px;
  }
  .mission-item,
  .step {
    max-width: 100%;
    padding: 15px;
    height: auto;
  }

  .projects-container {
    gap: 15px;
    padding: 0 15px;
  }

  .download-section {
    padding: 30px 15px;
  }
}

@media (max-width: 768px) {
  .about-intro h2 {
    font-size: 2rem;
  }
  .about-intro p {
    font-size: 1.1rem;
  }
  .download-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
    margin-top: 15px;
  }
  .mission-item,
  .step {
    flex: 1 1 100%; /* 모바일에서는 한 줄 전체를 차지하게 */
  }
}

@media (max-width: 480px) {
  .about-intro h2 {
    font-size: 1.8rem;
  }
  .about-intro p {
    font-size: 1rem;
  }
  .download-btn {
    padding: 8px 16px;
    font-size: 0.85rem;
  }
}
</style>
