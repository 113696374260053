<template>
  <section class="contact-page">
    <!-- <h1>아차차와 함께할 준비가 되셨나요?</h1> -->
    <ContactForm />
    <ContactInfo />
    <ContactMap />
  </section>
</template>

<script>
import ContactForm from "@/components/contact/ContactForm.vue";
import ContactInfo from "@/components/contact/ContactInfo.vue";
import ContactMap from "@/components/contact/ContactMap.vue";

export default {
  name: "Contact",
  components: {
    ContactForm,
    ContactInfo,
    ContactMap,
  },
};
</script>

<style scoped>
.contact-page {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 60px 20px;
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: white;
  font-weight: 400;
}
</style>
