<template>
  <section class="brand-intro">
    <h2 class="section-title">About Us</h2>
    <p class="description">
      우리는 Visual Jockey, Media Interaction, Software Development를 통해
      브랜드와 사람을 연결하는 경험을 제공합니다.
    </p>

    <div class="brand-values">
      <div class="value-item">
        <h3>🎭 창의적인 연출</h3>
        <p>공연과 미디어에 감각적인 비주얼을 더합니다.</p>
      </div>
      <div class="value-item">
        <h3>🚀 혁신적인 기술</h3>
        <p>Media &amp; Tech를 결합하여 색다른 경험을 제공합니다.</p>
      </div>
      <div class="value-item">
        <h3>🎯 차별화된 브랜드 경험</h3>
        <p>브랜드의 스토리를 감각적인 비주얼로 전달합니다.</p>
      </div>
    </div>

    <!-- 회사 소개서 다운로드 버튼 -->
    <a href="/docs/ACHACHA.inc_PR_2025.pdf" download class="download-btn">
      <font-awesome-icon icon="file-pdf" />
      회사 소개서 다운로드
    </a>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

// 아이콘 라이브러리 등록
library.add(faFilePdf);

export default {
  name: "MiniBrandIntro",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.brand-intro {
  padding: 80px 20px;
  text-align: center;
  background-color: #fff;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

.description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.brand-values {
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.value-item {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 30%;
}

.value-item h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.value-item p {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

.download-btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #ff6600;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}

.download-btn:hover {
  background: #cc5200;
}

/* 반응형 (모바일) */
@media (max-width: 768px) {
  .brand-intro {
    padding: 40px 10px;
  }

  .section-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .brand-values {
    flex-direction: column;
    gap: 15px;
    max-width: 90%;
    margin: 0 auto;
  }

  .value-item {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .value-item h3 {
    font-size: 1.1rem;
    margin-bottom: 5px;
  }

  .value-item p {
    font-size: 0.85rem;
  }

  .download-btn {
    padding: 8px 16px;
    font-size: 0.85rem;
    margin-top: 15px;
  }
}
</style>
