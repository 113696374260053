<!-- ✅ views/Home.vue -->
<template>
  <div>
    <HeroSection />
    <ServiceSection />
    <PortfolioGride :limit="12" />
    <!-- ✅ 12개만 표시 -->
    <MiniBrandIntro />
  </div>
</template>

<script>
import HeroSection from "@/components/home/HomeCarousel.vue";
import MiniBrandIntro from "@/components/home/MiniBrandIntro.vue";
import ServiceSection from "@/components/home/ServicePreview.vue";
import PortfolioGride from "@/components/works/PortfolioGride.vue";

export default {
  name: "Home",
  components: {
    HeroSection,
    ServiceSection,
    PortfolioGride,
    MiniBrandIntro,
  },
};
</script>
