import { createApp } from "vue";
import App from "./App.vue";
import router from "./router"; // 라우터 설정 파일
import "./assets/main.scss"; // 전역 폰트 설정

const app = createApp(App);

app.use(router); // Vue Router 플러그인 등록

app.mount("#app");
