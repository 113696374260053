<template>
  <section v-if="portfolio" class="portfolio-detail fade-in">
    <!-- 🔹 메인 이미지 -->
    <div class="portfolio-image">
      <img
        :src="currentImage"
        :alt="portfolio.title"
        class="image-fade"
        @click="openPopup"
      />
    </div>

    <!-- 🔹 서브 이미지 목록 -->
    <div
      v-if="portfolio.subImages && portfolio.subImages.length"
      class="sub-images"
    >
      <!-- 좌측 화살표 -->
      <button v-if="showLeftArrow" class="arrow left" @click="scrollLeft">
        ◀
      </button>

      <div
        class="sub-images-container"
        ref="subImageContainer"
        @scroll="updateArrows"
      >
        <img
          v-for="(img, index) in portfolio.subImages"
          :key="index"
          :src="img"
          :alt="'Sub image ' + (index + 1)"
          @click="currentImage = img"
        />
      </div>

      <!-- 우측 화살표 -->
      <button v-if="showRightArrow" class="arrow right" @click="scrollRight">
        ▶
      </button>
    </div>

    <!-- 🔹 프로젝트 정보 -->
    <div class="portfolio-info">
      <h1>{{ portfolio.title }}</h1>
      <p class="meta"><strong>📅 날짜:</strong> {{ portfolio.date }}</p>
      <p class="meta"><strong>📍 장소:</strong> {{ portfolio.location }}</p>
      <p class="description">{{ portfolio.description }}</p>

      <ul class="tasks">
        <li v-for="(task, index) in portfolio.tasks" :key="index">
          ✅ {{ task }}
        </li>
      </ul>
    </div>

    <!-- 🔹 Prev / Next Navigation -->
    <div class="navigation-buttons">
      <router-link
        v-if="!prevPortfolio"
        to="/works"
        class="nav-button works-btn"
      >
        ← Works
      </router-link>
      <router-link
        v-if="prevPortfolio"
        :to="`/works/${prevPortfolio.id}`"
        class="nav-button prev"
      >
        ← {{ prevPortfolio.title }}
      </router-link>
      <router-link
        v-if="nextPortfolio"
        :to="`/works/${nextPortfolio.id}`"
        class="nav-button next"
      >
        {{ nextPortfolio.title }} →
      </router-link>
    </div>

    <!-- 🔹 이미지 팝업 -->
    <div v-if="popupVisible" class="image-popup" @click="closePopup">
      <img :src="currentImage" alt="Popup Image" />
    </div>
  </section>
</template>

<script>
import { watch, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import portfolioData from "@/data/portfolioData.js";

export default {
  name: "PortfolioDetail",
  setup() {
    const route = useRoute();

    // ✅ 상태 변수
    const portfolio = ref(null);
    const prevPortfolio = ref(null);
    const nextPortfolio = ref(null);
    const currentImage = ref("");
    const popupVisible = ref(false);

    // ✅ 서브 이미지 슬라이드
    const subImageContainer = ref(null);
    const showLeftArrow = ref(false);
    const showRightArrow = ref(false);

    // ✅ 포트폴리오 업데이트 함수
    const updatePortfolio = (portfolioId) => {
      const currentIndex = portfolioData.findIndex(
        (item) => item.id === portfolioId
      );
      if (currentIndex !== -1) {
        portfolio.value = portfolioData[currentIndex];
        currentImage.value = portfolio.value.image;
        prevPortfolio.value =
          currentIndex > 0 ? portfolioData[currentIndex - 1] : null;
        nextPortfolio.value =
          currentIndex < portfolioData.length - 1
            ? portfolioData[currentIndex + 1]
            : null;

        // ✅ 서브 이미지 슬라이드 화살표 업데이트
        setTimeout(updateArrows, 200);
      }
    };

    // ✅ URL 변경 감지
    watch(
      () => route.params.id,
      (newId) => updatePortfolio(newId)
    );

    // ✅ 최초 실행 시 데이터 로드
    onMounted(() => {
      updatePortfolio(route.params.id);
    });

    // ✅ 팝업 관리
    const openPopup = () => (popupVisible.value = true);
    const closePopup = () => (popupVisible.value = false);

    // ✅ 서브 이미지 스크롤 기능
    const updateArrows = () => {
      if (!subImageContainer.value) return;
      showLeftArrow.value = subImageContainer.value.scrollLeft > 0;
      showRightArrow.value =
        subImageContainer.value.scrollLeft +
          subImageContainer.value.clientWidth <
        subImageContainer.value.scrollWidth;
    };

    const scrollLeft = () => {
      subImageContainer.value.scrollBy({ left: -300, behavior: "smooth" });
      setTimeout(updateArrows, 200);
    };

    const scrollRight = () => {
      subImageContainer.value.scrollBy({ left: 300, behavior: "smooth" });
      setTimeout(updateArrows, 200);
    };

    return {
      portfolio,
      prevPortfolio,
      nextPortfolio,
      currentImage,
      popupVisible,
      openPopup,
      closePopup,
      subImageContainer,
      showLeftArrow,
      showRightArrow,
      scrollLeft,
      scrollRight,
      updateArrows,
    };
  },
};
</script>

<style scoped>
/* ✅ 전체 레이아웃 */
.portfolio-detail {
  text-align: center;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  color: #fff;
}

/* ✅ 메인 이미지 */
.portfolio-image {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
/* ✅ 메인 이미지 스타일 (고정 높이, 비율 유지) */
.portfolio-image img {
  width: 100%;
  max-width: 600px;
  height: 400px; /* ✅ 고정 높이 설정 */
  object-fit: contain; /* ✅ 비율 유지하며 이미지 잘림 */
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

/* ✅ 이미지 호버 효과 */
.portfolio-image img:hover {
  transform: scale(1.05);
}

/* ✅ 서브 이미지 컨테이너 */
.sub-images {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 15px;
  gap: 5px;
  width: 100%;
}

/* ✅ 서브 이미지 슬라이드 - 스크롤바 숨김 */
.sub-images-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  max-width: 500px;
  padding: 5px;
  scroll-behavior: smooth;
  white-space: nowrap;

  /* ✅ 스크롤바 숨기기 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE, Edge */
}

/* ✅ 웹킷 브라우저(Chrome, Safari)에서 스크롤바 제거 */
.sub-images-container::-webkit-scrollbar {
  display: none;
}

/* ✅ 서브 이미지 슬라이드 */
.sub-images-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
  max-width: 500px;
  padding: 5px;
  scroll-behavior: smooth;
  white-space: nowrap;
}

/* ✅ 서브 이미지 스타일 */
.sub-images-container img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.sub-images-container img:hover {
  transform: scale(1.1);
}

/* ✅ 화살표 버튼 스타일 */
.arrow {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-in-out;
  position: static; /* ✅ 절대 위치 제거 */
}

/* ✅ 화살표 버튼이 이미지 리스트 양쪽에 붙도록 설정 */
.sub-images {
  display: flex;
  align-items: center;
  gap: 20px; /* ✅ 이미지와 화살표 간격 조절 */
}

/* ✅ 화살표 버튼 호버 효과 */
.arrow:hover {
  color: #ff6600;
}

/* ✅ 프로젝트 정보 섹션 */
.portfolio-info {
  text-align: center;
  margin-top: 30px;
}

.portfolio-info h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.meta {
  font-size: 1rem;
  color: #e2e2e2;
  margin-bottom: 10px;
}

.description {
  font-size: 1.2rem;
  color: #b1b1b1;
  margin-top: 20px;
}

/* ✅ 작업 내용 리스트 (가운데 정렬) */
.tasks {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tasks li {
  font-size: 1rem;
  color: #555;
  background: #f4f4f4;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  width: 90%;
  max-width: 600px;
  text-align: center;
}

/* ✅ 버튼 정렬 */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/* ✅ 버튼 기본 스타일 */
.nav-button {
  text-decoration: none;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  transition: background 0.3s ease;
}

/* ✅ "Works" 페이지 이동 버튼 */
.works-btn {
  background: #333;
  color: white;
}

.works-btn:hover {
  background: #555;
}

/* ✅ "Prev" 버튼 */
.prev {
  background: #ff6600;
  color: white;
}

.prev:hover {
  background: #cc5200;
}

/* ✅ "Next" 버튼 */
.next {
  background: #007bff;
  color: white;
}

.next:hover {
  background: #0056b3;
}

/* ✅ 모바일 반응형 */
@media (max-width: 768px) {
  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  .nav-button {
    width: 100%;
    margin-top: 10px;
  }
}

/* ✅ 팝업 이미지 */
.image-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-popup img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

/* ✅ 모바일 반응형 */
@media (max-width: 768px) {
  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  .nav-button {
    width: 100%;
    margin-top: 10px;
  }
  .sub-images-container {
    max-width: 300px;
  }

  .arrow {
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
  }

  .left {
    left: -30px;
  }

  .right {
    right: -30px;
  }
}
</style>
