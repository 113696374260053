<template>
  <div class="contact-info">
    <h2>연락처 정보</h2>
    <p>아래 정보를 통해 언제든지 문의하세요.</p>
    <ul>
      <li><strong>전화:</strong> +82 10-4124-6539</li>
      <li><strong>이메일:</strong> rogan@achacha.kr</li>
      <li><strong>주소:</strong> 서울특별시 관악구 남부순환로 226길 20</li>
      <li>
        <a
          href="https://www.instagram.com/achacha_vj"
          target="_blank"
          class="social-icon"
        >
          <font-awesome-icon icon="fa-brands fa-instagram" />
          Instagram
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

library.add(faInstagram);

export default {
  name: "ContactInfo",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.contact-info {
  color: white;
  text-align: center;
  margin-top: 40px;
  padding: 20px;
}

.contact-info h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.contact-info ul {
  list-style: none;
  padding: 0;
}

.contact-info li {
  font-size: 1.2rem;
  margin: 10px 0;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #ff6600;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #cc5200;
}
</style>
