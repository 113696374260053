<template>
  <section class="service-section">
    <h2 class="section-title">Our Services</h2>
    <div class="service-grid">
      <div
        class="service-card"
        v-for="(service, index) in services"
        :key="index"
      >
        <div class="icon">
          <img :src="service.icon" :alt="service.title" />
        </div>
        <h3>{{ service.title }}</h3>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServicePreview",
  data() {
    return {
      services: [
        {
          icon: "/icons/vj-icon.svg", // SVG 아이콘 경로
          title: "Visual Jockey",
          description:
            "공연, 방송, 이벤트에서 생동감 넘치는 비주얼 연출을 제공합니다.",
        },
        {
          icon: "/icons/media-interaction.svg",
          title: "Media Interaction",
          description:
            "디지털 미디어와 기술의 결합을 통해 새로운 경험을 만듭니다.",
        },
        {
          icon: "/icons/software-dev.svg",
          title: "Software Development",
          description:
            "창의적인 소프트웨어 솔루션을 개발하여 브랜드 가치를 높입니다.",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* 서비스 섹션 */
.service-section {
  padding: 80px 20px;
  text-align: center;
  background-color: #f8f9fa;
}

/* 섹션 제목 */
.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 40px;
}

/* 서비스 카드 그리드 */
.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

/* 개별 서비스 카드 */
.service-card {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* 아이콘 스타일 */
.icon img {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

/* 서비스 제목 */
.service-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

/* 서비스 설명 */
.service-card p {
  font-size: 1rem;
  color: #666;
}

/* 반응형 (모바일 최적화) */
@media (max-width: 768px) {
  .service-section {
    padding: 40px 10px;
  }
  .section-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  .service-grid {
    gap: 15px;
  }
  .service-card {
    padding: 20px;
  }
  .icon img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  .service-card h3 {
    font-size: 1.3rem;
    margin-bottom: 8px;
  }
  .service-card p {
    font-size: 0.9rem;
  }
}
</style>
