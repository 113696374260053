<template>
  <div class="works-page">
    <WorksHero />
    <WorksFilter @filter="applyFilter" />
    <PortfolioGride :selectedCategory="selectedCategory" />
  </div>
</template>

<script>
import WorksHero from "../components/works/WorksHero.vue";
import WorksFilter from "../components/works/WorksFilter.vue";
import PortfolioGride from "../components/works/PortfolioGride.vue";
import { ref } from "vue";

export default {
  name: "Works",
  components: { WorksHero, WorksFilter, PortfolioGride },
  setup() {
    const selectedCategory = ref("All");

    const applyFilter = (category) => {
      selectedCategory.value = category;
    };

    return { selectedCategory, applyFilter };
  },
};
</script>
