import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import About from "../views/AboutSection.vue";
import Works from "../views/WorksView.vue";
import Contact from "../views/ContactView.vue";
import PortfolioDetail from "../components/works/PortfolioDetail.vue";
// 필요한 페이지들을 import

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/about", name: "About", component: About },
  { path: "/works", name: "Works", component: Works },
  {
    path: "/works/:id",
    name: "PortfolioDetail",
    component: PortfolioDetail,
    props: true,
  },
  { path: "/contact", name: "Contact", component: Contact },
  // 나머지 라우트 설정
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 이전 위치가 저장되어 있다면 해당 위치로 스크롤
    if (savedPosition) {
      return savedPosition;
    } else {
      // 그렇지 않으면 맨 위로 스크롤
      return { top: 0 };
    }
  },
});

export default router;
