<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <!-- 👈 Footer 추가 -->
  </div>
</template>

<script>
import Header from "./components/common/HeaderComponent.vue";
import Footer from "./components/common/FooterComponent.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #000;
}
</style>
