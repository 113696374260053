<template>
  <section class="carousel-section">
    <Swiper
      :slides-per-view="1"
      :loop="true"
      :autoplay="{ delay: 5000, disableOnInteraction: false }"
      :pagination="{ clickable: true }"
      :modules="[Autoplay, Pagination]"
      class="carousel-container"
    >
      <SwiperSlide v-for="(slide, index) in slides" :key="index">
        <div class="slide" :style="{ backgroundImage: `url(${slide.image})` }">
          <div class="overlay"></div>
          <div class="text-content">
            <h1>{{ slide.title }}</h1>
            <p>{{ slide.subtitle }}</p>
            <!-- 첫 번째 슬라이드에서는 버튼 숨기기 -->
            <router-link v-if="index !== 0" to="/works" class="cta-button">
              더 알아보기
            </router-link>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

// 슬라이드 데이터
const slides = [
  {
    image: "/images/Carousel_Img03.png",
    title: "",
    subtitle: "",
  },
  {
    image: "/images/Carousel_Img02.png",
    title: "VJ Performance",
    subtitle: "생동감 넘치는 비주얼로 공연 연출의 감동을 극대화합니다.",
  },
  {
    image: "/images/Carousel_Img01.png",
    title: "Interactive Media Experience",
    subtitle: "미디어와 기술의 결합으로 특별한 경험을 선사합니다.",
  },
];
</script>

<style scoped>
/* 캐러셀 기본 스타일 */
.carousel-section {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.slide {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  height: auto;
  aspect-ratio: 21 / 9; /* 21:9 비율 유지 */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* ✅ 모바일에서 캐러셀 높이 증가 */
@media (max-width: 768px) {
  .slide {
    aspect-ratio: 16 / 9; /* 16:9 비율로 더 높게 설정 */
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.text-content {
  position: absolute;
  z-index: 2;
  max-width: 60%;
  padding: 20px;
  text-align: center;
}

/* PC (기본) - 현재 버전 */
.text-content h1 {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background: #ff6600;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
  margin-top: 10px;
}

.cta-button:hover {
  background: #cc5200;
}

/* Swiper 페이지네이션 스타일 */
:deep(.swiper-pagination-bullet) {
  background: #fff !important;
  opacity: 0.6;
  width: 10px;
  height: 10px;
  transition: opacity 0.3s;
}

:deep(.swiper-pagination-bullet-active) {
  background: #ff6600 !important;
  opacity: 1;
  width: 10px;
  height: 10px;
}

/* Tablet (max-width: 1024px) - 약간 축소 */
@media (max-width: 1024px) {
  .text-content {
    max-width: 75%;
  }
  .text-content h1 {
    font-size: 2.8rem;
  }
  .text-content p {
    font-size: 1.1rem;
  }
}

/* Mobile (max-width: 768px) - 이전 버전 스타일 (텍스트 크게 보임) */
@media (max-width: 768px) {
  .text-content {
    max-width: 85%;
    padding: 10px;
  }
  .text-content h1 {
    font-size: 1.5rem; /* 기존 1.8rem → 1.5rem */
    margin-bottom: 8px;
  }
  .text-content p {
    font-size: 0.85rem; /* 기존 0.9rem → 0.85rem */
    margin-bottom: 12px;
  }
  .cta-button {
    padding: 6px 14px; /* 기존 8px 16px → 6px 14px */
    font-size: 0.8rem; /* 기존 0.85rem → 0.8rem */
    margin-top: 8px; /* 버튼과 텍스트 간격 확보 */
  }

  /* 상단 로고 및 햄버거 아이콘 침범 방지 (필요 시 추가) */
  .text-content {
    margin-top: 30px;
  }
}
</style>
