<!-- src/components/works/PortfolioItem.vue -->
<template>
  <router-link v-if="item" :to="`/works/${item.id}`" class="portfolio-item">
    <img :src="item.image" :alt="item.title" />
    <div class="portfolio-info">
      <h3>{{ item.title }}</h3>
      <p>{{ item.category }}</p>
    </div>
  </router-link>
  <p v-else class="error-message">포트폴리오 정보를 불러올 수 없습니다.</p>
</template>

<script>
export default {
  name: "PortfolioItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
/* ✅ 카드 전체를 클릭 가능하도록 */
.portfolio-item {
  display: block; /* ✅ 링크 요소이므로 block 설정 */
  text-decoration: none; /* ✅ 기본 텍스트 스타일 제거 */
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer; /* ✅ 클릭 가능 표시 */
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.portfolio-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.portfolio-info {
  padding: 15px;
  text-align: center;
  color: black; /* ✅ 기본 텍스트 컬러 */
}

.portfolio-info h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

.portfolio-info p {
  font-size: 1rem;
  color: #666;
}

/* ✅ 에러 메시지 스타일 */
.error-message {
  text-align: center;
  color: red;
  font-size: 1.2rem;
  margin-top: 10px;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .portfolio-info {
    padding: 10px;
  }

  .portfolio-info h3 {
    font-size: 0.8rem;
  }

  .portfolio-info p {
    font-size: 0.6rem;
  }
}

@media (max-width: 480px) {
  .portfolio-item {
    margin: 1px;
  }

  .portfolio-info {
    padding: 10px;
  }

  .portfolio-info h3 {
    font-size: 0.5rem;
  }

  .portfolio-info p {
    font-size: 0.4rem;
  }
}
</style>
